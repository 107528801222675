
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppCoachTrainingsCategoriesListFormUpdate} from "@/models/app/coach/trainings/categories/list/form/update";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElRow,
  ElCol,
  ElForm,
  ElFormItem,
  ElInput,
  ElSelect,
  ElOption,
  ElButton,
} from "element-plus";
import {
  Edit,
  Bottom,
  Top,
  CircleClose,
  CirclePlus,
} from "@element-plus/icons-vue";

// Services
import {postRequest} from "@/services/api/request";

@Options({
  props: [
    "trainingCategory",
  ],
  components: {
    ElDialog,
    ElRow,
    ElCol,
    ElForm,
    ElFormItem,
    ElInput,
    ElSelect,
    ElOption,
    ElButton,
    Edit,
    Bottom,
    Top,
    CircleClose,
    CirclePlus,
  },
})
export default class AppCoachTrainingsCategoriesListFormUpdateIndexVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;

  trainingCategory: AppCoachTrainingsCategoriesListFormUpdate = new AppCoachTrainingsCategoriesListFormUpdate();

  formData: AppCoachTrainingsCategoriesListFormUpdate = new AppCoachTrainingsCategoriesListFormUpdate();

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  addField(): void {
    this.formData.content.fields.push({
      name: "",
      property: "",
      type: "",
    });
  }

  removeField(index: number): void {
    this.formData.content.fields = this.formData.content.fields.filter((item, key) => index !== key);
  }

  sortUpField(index: number): void {
    let element = this.formData.content.fields[index];
    this.formData.content.fields.splice(index, 1);
    this.formData.content.fields.splice(index - 1, 0, element);
  }

  sortDownField(index: number): void {
    let element = this.formData.content.fields[index];
    this.formData.content.fields.splice(index, 1);
    this.formData.content.fields.splice(index + 1, 0, element);
  }

  setProperty(index: number): void {
    this.formData.content.fields[index].property = this.formData.content.fields[index].name.toLowerCase().replace(/ /g, '-');
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/administration/training-category/update",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getTrainingCategories');
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  setFormData(): void {
    this.formData = this.trainingCategory;
  }

  resetFormData(): void {
    this.formData = new AppCoachTrainingsCategoriesListFormUpdate();
  }

  updated(): void {
    this.setFormData();
  }
}
