
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import CoachNavigationVue from "@/components/coach/navigation.vue";
import CoachTrainingsNavigationVue from "@/components/coach/trainings/navigation.vue";
import AppCoachTrainingsCategoriesListIndexVue from "@/views/app/coach/trainings/categories/list/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    CoachNavigationVue,
    CoachTrainingsNavigationVue,
    AppCoachTrainingsCategoriesListIndexVue,
  },
})
export default class AppCoachTrainingsCategoriesIndexVue extends Vue {}
